// app/javascript/application.js
// Entry point for the build script in your package.json
import jquery from 'jquery';
window.$ = window.jquery = jquery;

import "@popperjs/core";
import "@hotwired/turbo-rails";
import "bootstrap";
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import "./controllers";
import "./controllers/geolocation_controller";
import "./controllers/autocomplete";
import "./controllers/sidebar_toggle";
import "./controllers/auth_modal";
