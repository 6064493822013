// app/javascript/controllers/geolocation_controller.js

import { renderListingCards } from './listing_cards';

// Function to fetch listings by geolocation
async function fetchByGeolocation(latitude, longitude) {
  // Define listingsContainer here.
  // Fetch the DOM element where listings will be displayed. This allows us to manipulate this container later.
  const listingsContainer = document.querySelector(".listing-grid");

  // Make a GET request to fetch listings
  fetch(`/fetch_listings?latitude=${latitude}&longitude=${longitude}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  .then(response => response.json())
  .then(data => {
    console.log("Received data:", data);
    // Call the function to render listing cards
    renderListingCards(data);

    // Cache latitude and longitude in sessionStorage to reuse without re-fetching
    sessionStorage.setItem('geoLocation', JSON.stringify({latitude, longitude}));
  })
  .catch((error) => console.error('Error:', error));
}

// Execute when the page loads
document.addEventListener("turbo:load", function() {
  // Retrieve cached geolocation from sessionStorage
  const cachedGeoLocation = sessionStorage.getItem('geoLocation');
  if (cachedGeoLocation) {
    const { latitude, longitude } = JSON.parse(cachedGeoLocation);
    // If cached geolocation is found, use it to fetch listings
    fetchByGeolocation(latitude, longitude);
  } else {
  // If no cached geolocation, check the browser's geolocation API to get current location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Fetch listings based on current geolocation and cache it
        fetchByGeolocation(latitude, longitude);
      }, () => {
        // Handle cases where geolocation permission is denied
        console.error("Geolocation is not supported by this browser.");
      });
    } else {
      // Handle cases where geolocation is not supported by the browser
      console.error("Geolocation is not supported by this browser.");
    }
  }

  // Listen for an event to reset geolocation
  document.addEventListener("reset-geolocation", function() {
    // Remove cached geolocation data when resetting
    sessionStorage.removeItem('geoLocation');
    // logic to refresh geolocation and subsequently listings
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Fetch listings based on new geolocation data
        fetchByGeolocation(latitude, longitude);
      });
    }
  });
});
