// In app/javascript/packs/auth_modal.js


// Working code!
// Function to hide the modal
function hideModal() {
  const modal = document.getElementById('auth-modal-container');
  const overlay = document.getElementById('modal-overlay');

  if (modal) {
    modal.classList.add('hidden'); // Hide the modal by adding 'hidden' class
  }
  if (overlay) {
    overlay.classList.remove('active'); // Hide the overlay by removing 'active' class
  }
}

// Add a function or logic to show the modal and overlay
function showModal() {
  const modal = document.getElementById('auth-modal-container');
  const overlay = document.getElementById('modal-overlay');

  if (modal) {
    modal.classList.remove('hidden'); // Show the modal by removing 'hidden' class
  }
  if (overlay) {
    overlay.classList.add('active'); // Show the overlay by adding 'active' class
  }
}

// Function to set up the toggle behavior for login and signup
function setupModalToggle() {
  const loginBtn = document.getElementById('login');
  const signupBtn = document.getElementById('signup');

  if (loginBtn) {
    loginBtn.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent default button action
      document.querySelector('.login').classList.toggle('slide-up');
      document.querySelector('.signup').classList.toggle('slide-up');
    });
  }

  if (signupBtn) {
    signupBtn.addEventListener('click', (e) => {
      e.preventDefault(); // Prevent default button action
      document.querySelector('.login').classList.toggle('slide-up');
      document.querySelector('.signup').classList.toggle('slide-up');
    });
  }
}

// Event listener for clicks outside the modal
function setupOutsideClickListener() {
  document.addEventListener('click', function (e) {
    const modal = document.getElementById('auth-modal-container');
    if (modal && !modal.contains(e.target) && !e.target.closest('.trigger-button-class')) { // Replace 'trigger-button-class' with your actual trigger button class
      hideModal();
    }
  });
}

// Listen for Turbo events to set up the modal behavior
document.addEventListener('turbo:load', function () {
  setupModalToggle();
  setupOutsideClickListener();
});

// Ensure the modal toggle is set up after a frame is loaded
document.addEventListener('turbo:frame-load', function (e) {
  if (e.target.id === 'auth_modal') {
    setupModalToggle();

    // Activate the overlay when the auth_modal frame is loaded
    const overlay = document.getElementById('modal-overlay');
    if (overlay) {
      overlay.classList.add('active');
    }
  }
});
