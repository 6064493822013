// app/javascript/controllers/sidebar_toggle.js

document.addEventListener("turbo:load", function() {
  const sidebar = document.querySelector('nav.sidebar');
  const toggle = document.querySelector('.sidebar .toggle');

  if (toggle) {
    // Remove any existing event listeners to avoid duplication
    toggle.removeEventListener('click', toggleSidebar);
    // Re-bind event listener
    toggle.addEventListener('click', toggleSidebar);
  }

  function toggleSidebar() {
    sidebar.classList.toggle("close");
    toggle.classList.toggle("rotated");
  }
});
