// app/javascript/controllers/autocomplete.js

import { renderListingCards } from './listing_cards';

// Execute when the page loads
document.addEventListener("turbo:load", function() {
  // Initialize Google Maps Autocomplete
  const input = document.getElementById("autocomplete");
  const autocomplete = new window.google.maps.places.Autocomplete(input);

  // Listen for changes in the autocomplete field
  autocomplete.addListener('place_changed', async function() {
    const place = autocomplete.getPlace();

    // Check if the place has geometry; if not, return
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    // Extract latitude and longitude
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    // Fetch listings based on the selected place
    fetch(`/fetch_listings?latitude=${latitude}&longitude=${longitude}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log("Received data:", data);
      // Call the function to render listing cards
      renderListingCards(data);

      // Store the user's current latitude and longitude as data attributes on the listings container.
      // Useful for storing the last known latitude and longitude values to retrieve them later without having to ask browser for geolocation again
      listingsContainer.setAttribute("data-latitude", latitude);
      listingsContainer.setAttribute("data-longitude", longitude);
    })
    .catch((error) => console.error('Error:', error));
  });

  // Listen for the Reset button to clear the input and reset geolocation
  const resetButton = document.getElementById("reset-button");
  if (resetButton) {
    resetButton.addEventListener("click", function() {
      // Clear the autocomplete input
      input.value = "";

      // Dispatch an event to refresh listings based on geolocation
      const event = new Event('reset-geolocation');
      document.dispatchEvent(event);
    });
  }
});
