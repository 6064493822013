// app/javascript/controllers/listing_cards.js

// Function to render listing cards
export function renderListingCards(data) {
  // Fetch the DOM element 'listings container' where listings will be displayed
  const listingsContainer = document.querySelector(".listing-grid");
  // Clear content of listings container
  listingsContainer.innerHTML = "";
  // Store all cards' HTML here
  let allCardsHTML = "";

  // Loop through each listing and generate HTML for it
  data.listings.forEach(item => {
    const listing = item.listing;
    const distance = item.distance;
    const duration = item.duration;
    const type = item.type;
    const additional_attrs = item.listing.additional_attrs;
    const activityTypes = additional_attrs.activity_types ? additional_attrs.activity_types.join(', ') : "";
    const priceRange = additional_attrs.price_range ? additional_attrs.price_range : "N/A";
    const ageGroups = additional_attrs.age_groups ? additional_attrs.age_groups.join(', ') : "";
    const dietaryOptions = additional_attrs.dietary_options ? additional_attrs.dietary_options.join(', ') : "";

    // Initialize carousel items and indicators for image display
    let carouselItems = '';
    let carouselIndicators = '';
    if (Array.isArray(listing.image_urls)) {
      listing.image_urls.forEach((url, idx) => {
        const activeClass = idx === 0 ? 'active' : '';
        carouselItems += `
          <div class="carousel-item ${activeClass}">
            <img src="${url}" class="carousel-image" alt="${listing.name}" loading="lazy">
          </div>
        `;
        carouselIndicators += `
          <button type="button" data-bs-target="#carouselExampleIndicators${listing.id}" data-bs-slide-to="${idx}" class="${activeClass}" aria-current="true" aria-label="Slide ${idx + 1}"></button>
        `;
      });
    }

    // Determine the URL based on the listing type
    let url = "";
    if (type === "Accommodation") {
      url = `/accommodations/${listing.id}`;
    } else if (type === "Activity") {
      url = `/activities/${listing.id}`;
    } else if (type === "Eatery") {
      url = `/eateries/${listing.id}`;
    }

    // Ternary operator to decide which HTML to include
    const cardInfoHTML = type === 'Activity' ?
      `<span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1696502060/LittleAdventures/Icons/ndod0nypyh0ingikxasl.png" class="card-icon"> ${activityTypes}</span>` :
      `<span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1696502019/LittleAdventures/Icons/l1290tg1wsg3lu8qv3ky.png" class="card-icon"> ${dietaryOptions}</span>`;


    // Complete HTML for a listing card
    const listingCard = `
      <div class="listing-card">
        <div class="card h-100">
          <div id="${type.toLowerCase()}Carousel${listing.id}" class="carousel slide">
            <div class="carousel-indicators">
              ${carouselIndicators}
            </div>
            <div class="carousel-inner">
              ${carouselItems}
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#${type.toLowerCase()}Carousel${listing.id}" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#${type.toLowerCase()}Carousel${listing.id}" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <div class="card-body">
            <a href="${url}" class="card-link">
              <span class="card-info-title">${listing.name}</span>
              <span class="card-info-title">${listing.location.city}</span>
            </a>
            <span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1696502068/LittleAdventures/Icons/bq45ufcjwfnjaj8k3qls.png" class="card-icon"> ${distance} km</span>
            <span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1696501968/LittleAdventures/Icons/lfav3ozdu3v003mjyiun.png" class="card-icon"> ${duration}</span>
            ${cardInfoHTML}
            <span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1695424479/LittleAdventures/Icons/fk2xtkele2fxe2bgubkg.png" class="card-icon"> ${priceRange}</span>
            <span class="card-info"><img src="https://res.cloudinary.com/little-adventures/image/upload/v1695424731/LittleAdventures/Icons/dwrgwpo1w7ihyerfxztn.png" class="card-icon"> ${ageGroups}</span>
          </div>
        </div>
      </div>
    `;
    allCardsHTML += listingCard;  // Append this card's HTML to the total HTML
  });

  listingsContainer.innerHTML = allCardsHTML;  // Append all cards' HTML at once
}
